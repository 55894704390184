.video-container {
  position: relative;
  width: 100%;
  max-width: 610px;
  height: 610px;
  border-radius: 1rem;

  @media (min-width:768px) {
    height: 500px;
    max-width: 445px;
  }

  @media (min-width:992px) {
    max-width: 610px;
    height: 610px;
  }

  @media(min-width:1520px) {
    max-width: 685px;
    height: 685px;
  }
}

.video-poster {
  width: 100%;
  height: 100%;
  border-radius: 1.5rem;
  object-fit: cover;

}

.video-player.hidden {
  display: none;
}

.video-player.visible {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.5rem;
}

.container-form {
  max-width: 300px;

  input::placeholder {
    color: #FFF !important;
    opacity: 0.5 !important;
  }
}

.container-form[data-display='desktop'] {
  max-width: 483px;
}

.title {
  text-align: center;
  font-size: 32px !important;
}

.title[data-display='desktop'] {
  font-size: 52px !important;
}

.conteiner-subtitle {
  max-width: 300px;
}

.conteiner-subtitle[data-display="desktop"] {
  max-width: 406px
}

.subtitle {
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

.subtitle[data-display="desktop"] {
  font-size: 24px;
}

.button-price {
  height: 47px;
  border-radius: 0.65rem;

}

.button-price[data-display='desktop'] {
  max-width: 90px;
}

.button-text {
  white-space: nowrap !important;
  font-size: 20px !important;
}

.input-price {
  width: 100% !important;
  height: 47px !important;
  border-radius: 0.65rem !important;
  border: solid 1px #fff !important;
  background: transparent !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 16px !important;

  /* Hide number input arrows */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

[data-status="error"] .input-price {
  border: solid 1px #FF5452 !important;
}

.input-price[data-display='desktop'] {
  max-width: 170px;
  width: auto
}

.container-btn-submit {
  max-width: 300px;
  max-height: 60px;
}

.container-btn-submit[data-display='desktop'] {
  max-width: 481px
}

.link {
  width: 100% !important;
}

.button-submit {
  width: 100%;
  height: 60px;
}

.button-submit-text {
  font-size: 20px !important
}

.container-slide {
  position: absolute;
  top: 80px;
  left: 0;
  width: 80vw !important;
  overflow: hidden;
}

.container-slide[data-display='desktop'] {
  top: 110px;
}

.banner {
  @media(max-width:1151px) {
    align-items: flex-start !important;
  }
}
