.section-conteiner {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  background: var(--primary);
  min-height: 55vh;
  padding-top: 44px;
  z-index: 1;
  padding-bottom: 0.5rem;
}

@media (max-height: 700px) {
  .section-conteiner {
    margin-top: -3rem;
    padding-top: 24px;
  }
}

.title {
  text-align: center;
  font-size: 36px !important;
  line-height: 36px;
}

.subtitle-conteiner {
  max-width: 300px;

  @media (max-width: 768px) {
    max-width: none;
  }
}

.subtitle-text {
  font-size: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 18px !important;
  }
}

.container-form {
  max-width: 299px;

  input {
    font-size: 16px;
  }

  input::placeholder {
    color: #FFF !important;
    opacity: 0.5 !important;
  }
}

.button-price {
  height: 47px;
  width: 90px;
  border-radius: 0.65rem;
}

.button-text {
  white-space: nowrap;
  font-size: 20px !important;
}

.input-price {
  width: 100% !important;
  height: 47px !important;
  border-radius: 0.65rem !important;
  border: solid 1px #fff !important;
  background: transparent !important;
  color: #fff !important;
  text-align: center !important;
}

[data-status="error"] .input-price {
  border: solid 1px #FF5452 !important;
}

.container-btn-submit {
  max-width: 300px;
  max-height: 60px;
}

.link {
  width: 100% !important;
}

.container-slide {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
